import 'core-js/stable'
import Vue from 'vue'
import '@common/utils/keycloak-impl'
import App from './App'
import SessionManager from '@common/utils/sessionManager'
import GlobalComponents from '@common/components/global-component.registration'
import ErrorApp from '@common/components/error-app';
document.addEventListener("application-down", function(e) { 
  new Vue({
    el: '#app',
    router,
    icons,
    template: '<ErrorApp/>',
    components: {
      ErrorApp
    }
  });
});
GlobalComponents.resgister(Vue);
Vue.config.performance = true
Vue.use(new SessionManager())
function loadVue() {
  new Vue({
    el: '#app',
    template: '<App/>',
    components: {
      App
    }
  });
}
KeycloakImpl().initiate(loadVue);
