<template>
  <div id="app">
    <img src="@main/assets/logo-2.png" class="logo"/>
    <div v-if="isLoggedId" class="product-div">
      <div v-if="error" class="error">
        {{error}}. Please contact your system adminsitrator.
      </div>
      <div v-if="noProducts" class="no-products">
        No Product registration found. Please contact your system adminsitrator.
      </div>
      <header-products></header-products>
    </div>
    <loader></loader>
    <sessionTimeOut></sessionTimeOut>
  </div>
</template>

<script>
import * as initService from '@common/service/init.service'
import Vue from "@common/utils/sessionManager";
import loader from '@common/components/loader/loader.vue'
import sessionTimeOut from '@common/components/session/sessiontimeout'
import * as loaderService from '@common/service/loader.service'
import * as apis from '@common/utils/api/products'
import {events,eventBus} from '@common/service/event.service';
export default {
  name: 'App',
  data : function(){
    return {
      isLoggedId:false,
      noProducts:false,
      error:"",
      products:[],
    }
  },
  created:function(){
    initService.initialize(this);
    eventBus.on(events.logout, () => {
      new Vue().logout()
      this.$router.push('/')
    });
  },
  components : {loader, sessionTimeOut},
  methods: {
    gotoProduct: function(product){
      window.open(`${window.location.protocol}//${window.location.host}/${product.url}`,`${product.name}_tab`);
    },
    getProductLists: function (code) {
        loaderService.showLoader();
        apis.getProductLists().then((response) => {
            if(response.data.status.status_code === 0) {
                this.products = response.data.payload;
                if(this.products.length == 0){
                  this.noProducts = true;
                }
            }
        }).catch(err=>{
            this.error = err?.response?.data?.payload ? err.response.data.payload : err.message;
        }).finally(()=>{
            loaderService.hideLoader();
        });
    },
  },
  mounted : function(){
    if(window.keycloakImplObj.userDetails){
      this.isLoggedId = true;
      this.getProductLists();
    }
  }
}
</script>

<style lang="scss">
  @import 'assets/scss/style';
</style>
